<template>
    <div>
        <div class="popup__header">
            <div class="title">Сдать чертежи на проверку</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="mb40">
                <input-styled v-model="form.text.value"
                              :placeholder="'Время синхронизации'"
                              :placeholder-input="'Например, «13:26»'"
                              :isError="form.text.error"
                              :errorText="form.text.errorText"
                              @focus="removeError(form.text)"
                              @input="removeError(form.text)"></input-styled>
            </div>
            <div class="text-color-grey text-uppercase text-size-xs">
                Проверяющий
            </div>
            <div class="text-bold text-size-xxl mt8 mb8 df">
                <span class="mr8">{{ inspector.lastName }} {{ inspector.firstName }}</span>
                <span class="member" :class="inspector.char">{{ inspector.groupCode }}{{ inspector.personalNum }}</span>
            </div>
            <div class="text-color-grey text-size-xs">
                В РАБОТЕ: 3D-МОДЕЛИ – <strong>{{ inspector.models }}</strong>, ЧЕРТЕЖИ – <strong>{{
                    inspector.drawings
                }}</strong>
            </div>
            <hr>
            <span class="text-italic text-color-grey">
                Проверяющему будет отправлено СМС о том, что нужно проверить<br>
                схемы и чертежи
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «На проверке»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Tumbler from '@/components/Tumbler.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import InputStyled from '@/components/forms/InputStyled.vue';

export default {
    name: 'DrawForReview',
    components: {
        Tooltip,
        Tumbler,
        SubmitBtn,
        InputStyled,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        send() {
            let error = false;
            for (let field in this.form) {
                this.form[field].error = false;
                let errorField = false;
                if (field === 'text' && this.form[field].value.length == 0) {
                    errorField = true;
                }

                if (errorField) {
                    error = true;
                    setTimeout(() => {
                        this.form[field].error = true;
                    });
                }
            }
            if (!error && !this.fileLoad) {
                let params = {
                    action: 'DrawForReview',
                    id: this.item.id,
                    params: {},
                };
                for (let field in this.form) {
                    params.params[field] = this.form[field].value;
                }
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', { id: this.item.id });
                    setTimeout(() => {
                        this.$store.commit('popups/close', { name: this.popups.drawForReview.name });
                    }, this.options.delayPopupClose);
                }, () => {});
            }
        },
    },
    data: function () {
        return {
            btnType: 'normal',
            form: {
                text: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
            },

        };
    },
    mounted() {

    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
        inspector() {
            let inspector = {};
            for (let i in this.item.extProps.team.users.inspector) {
                if (this.item.props.inspectorID == this.item.extProps.team.users.inspector[i].id) {
                    inspector = this.item.extProps.team.users.inspector[i];
                    break;
                }
            }

            return inspector;
        },
        designer() {
            let designer = {};
            for (let i in this.item.extProps.team.users.designer) {
                if (this.item.props.designerID === this.item.extProps.team.users.designer[i].id) {
                    designer = this.item.extProps.team.users.designer[i];
                    break;
                }
            }

            return designer;
        },
    },
};
</script>

<style scoped>

</style>
