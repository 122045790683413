<script>
import Tooltip from '@/components/Tooltip.vue';
import { mapState } from 'vuex';
export default {
    name: "RequestNew",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            popups: state => state.popups.items,
        }),
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
    },
    methods: {
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        },
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :isChangeablePos="false"
        :add-class-wrap="'details-tooltip'"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
               v-html="requestStatusTimePassed"></a>
        </template>
        <template v-slot:tooltipContent>{{ item.props.statusComment }}</template>
    </tooltip>
</template>

<style scoped>

</style>