<script>
import Tooltip from '@/components/Tooltip.vue';
import { mapState } from 'vuex';
export default {
    name: "RepairPrepay",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            popups: state => state.popups.items,
        }),
        requestRepairDesc() {
            return 'Предоплата ' + this.item.props.repairPrepaySum + ' <span class="rouble">' + this.$store.getters['options/setRouble'] + '</span>' + ' внесена ' + this.item.props.repairPrepayDate + ', ремонт запланирован на ' + this.item.props.repairPrepayMonth;
        },
    },
    methods: {
        openEditMasterCommentPrepay(item) {
            this.$nextTick(() => {
                this.$store.dispatch('popups/open', {name: this.popups.editMasterCommentPrepay.name, request: item});
            });
        },
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.extProps.state);
        }
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :isChangeablePos="false"
        :add-class-wrap="'details-tooltip'"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
               v-html="requestRepairDesc"></a>
        </template>

        <template v-slot:tooltipContent>
            {{ item.props.repairPrepayComment }} <br> <br>
            {{ item.props.repairPrepayMaster.lastName }} {{ item.props.repairPrepayMaster.firstName }}
            ({{ item.props.repairPrepayMaster.groupCode + item.props.repairPrepayMaster.number }}),
            {{ item.props.repairPrepayDate }}
            <br><br>
            <a @click="openEditMasterCommentPrepay(item);" href="javascript:void(0);"
               v-if="hasState(['repair_think_M','repair_reject_M','repair_prepay_M','repair_aside_M','repair_in_process_M','repair_terminate_M', 'repair_finish_M'])">
                <span class="i i-pencil2 --edit i--blue "></span>
                Редактировать
            </a>
        </template>
    </tooltip>
</template>

<style scoped>

</style>