<template>

    <!-- Дать заказчику подумать -->
    <popup :show="popups.letCustomerThink.show"
           :width="540"
           :name="popups.letCustomerThink.name">
        <let-customer-think/>
    </popup>

    <!-- Редактирование комментария мастером -->
    <popup :show="popups.editMasterComment.show"
           :width="540"
           :name="popups.editMasterComment.name">
        <edit-master-comment/>
    </popup>

    <!-- Редактирование комментария по предоплате мастером -->
    <popup :show="popups.editMasterCommentPrepay.show"
           :width="540"
           :name="popups.editMasterCommentPrepay.name">
        <edit-master-comment-prepay/>
    </popup>


    <!-- Отправить СМС заказчику -->
    <popup :show="popups.sendSms.show"
           :width="540"
           :name="popups.sendSms.name">
        <send-sms/>
    </popup>

    <!-- Дата замера -->
    <popup :show="popups.measureDate.show"
           :width="540"
           :name="popups.measureDate.name">
        <measure-date/>
    </popup>

    <!-- Что делать с освободившейся датой -->
    <popup :show="popups.availableDate.show"
           :width="540"
           :is-btn-close="false"
           :name="popups.availableDate.name">
        <available-date :date="'17 февраля 2020'"/>
    </popup>

    <!-- Данные заказчика -->
    <popup :show="popups.customerData.show"
           :width="540"
           :name="popups.customerData.name">
        <customer-data/>
    </popup>

    <!-- Характеристика заказчика -->
    <popup :show="popups.customerCharacter.show"
           :width="540"
           :name="popups.customerCharacter.name">
        <customer-character/>
    </popup>

    <!-- Адрес объекта -->
    <popup :show="popups.objectAddr.show"
           :width="540"
           :name="popups.objectAddr.name">
        <object-addr/>
    </popup>

    <!-- Обычный замер -->
    <popup :show="popups.standardMeasure.show"
           :width="540"
           :name="popups.standardMeasure.name">
        <standard-measure/>
    </popup>

    <!-- Дистанционный замер -->
    <popup :show="popups.remoteMeasure.show"
           :width="540"
           :name="popups.remoteMeasure.name">
        <remote-measure/>
    </popup>

    <!-- Отклонить заявку -->
    <popup :show="popups.rejectRequest.show"
           :width="540"
           :name="popups.rejectRequest.name">
        <reject-request/>
    </popup>
    <!-- Отклонить заявку сразу -->
    <popup :show="popups.rejectRequestNow.show"
           :width="540"
           :name="popups.rejectRequestNow.name">
        <reject-request :not-call="true"/>
    </popup>
    <!-- Отклонить заявку сразу -->
    <popup :show="popups.requestThink.show"
           :width="540"
           :name="popups.requestThink.name">
        <request-think/>
    </popup>

    <!-- Сообщить об отказе -->
    <popup :show="popups.rejectReport.show"
           :width="540"
           :name="popups.rejectReport.name">
        <reject-report/>
    </popup>

    <!-- Причина отказа -->
    <popup :show="popups.refuseReason.show"
           :width="540"
           :name="popups.refuseReason.name">
        <refuse-reason/>
    </popup>

    <!-- Деталка данных замера -->
    <measure-detail :show="popups.measureDetail.show"/>

    <!-- Назначить собеседование -->
    <popup :show="popups.scheduleInterview.show"
           :width="540"
           :name="popups.scheduleInterview.name">
        <schedule-interview/>
    </popup>
    <!-- Окончательно одобрить -->
    <popup :show="popups.approve.show"
           :width="540"
           :name="popups.approve.name">
        <approve/>
    </popup>

    <!-- Предварительно одобрить -->
    <popup :show="popups.preApprove.show"
           :width="540"
           :name="popups.preApprove.name">
        <pre-approve/>
    </popup>

    <!-- Отказать заказчику -->
    <popup :show="popups.refuseCustomer.show"
           :width="540"
           :name="popups.refuseCustomer.name">
        <refuse-customer/>
    </popup>

    <!-- Отменить замер -->
    <popup :show="popups.cancelMeasure.show"
           :width="540"
           :name="popups.cancelMeasure.name">
        <cancel-measure/>
    </popup>

    <!-- Отложить замер -->
    <popup :show="popups.deferMeasure.show"
           :width="540"
           :name="popups.deferMeasure.name">
        <defer-measure/>
    </popup>

    <!-- Подвердить замер-->
    <popup :show="popups.confirmMeasure.show"
           :width="540"
           :name="popups.confirmMeasure.name">
        <confirm-measure/>
    </popup>

    <!-- Назначить время замера -->
    <popup :show="popups.measureTime.show"
           :width="540"
           :name="popups.measureTime.name">
        <measure-time/>
    </popup>

    <!-- Выставить счёт -->
    <popup :show="popups.issueInvoice.show"
           :width="540"
           :name="popups.issueInvoice.name">
        <issue-invoice/>
    </popup>

    <!-- Принять наличные -->
    <popup :show="popups.acceptCash.show"
           :width="540"
           :name="popups.acceptCash.name">
        <accept-cash/>
    </popup>

    <!-- Принять на карту -->
    <popup :show="popups.acceptCard.show"
           :width="540"
           :name="popups.acceptCard.name">
        <accept-card/>
    </popup>

    <!-- Принять деньги от замерщика -->
    <popup :show="popups.acceptMeasurerMoney.show"
           :width="540"
           :name="popups.acceptMeasurerMoney.name">
        <accept-measurer-money/>
    </popup>

    <!-- Возобновить замер -->
    <popup :show="popups.resumeMeasure.show"
           :width="540"
           :name="popups.resumeMeasure.name">
        <resume-measure/>
    </popup>

    <!-- Причина откладывания -->
    <popup :show="popups.deferReason.show"
           :width="540"
           :name="popups.deferReason.name">
        <defer-reason/>
    </popup>

    <!-- Причина отклонения -->
    <popup :show="popups.rejectReason.show"
           :width="540"
           :name="popups.rejectReason.name">
        <reject-reason/>
    </popup>

    <!-- Причина отмены -->
    <popup :show="popups.cancelReason.show"
           :width="540"
           :name="popups.cancelReason.name">
        <cancel-reason/>
    </popup>

    <!-- Загрузить концепцию и замер -->
    <popup :show="popups.downloadConcept.show"
           :width="540"
           :name="popups.downloadConcept.name">
        <download-concept/>
    </popup>

    <!-- Принять концепцию -->
    <popup :show="popups.acceptConcept.show"
           :width="540"
           :name="popups.acceptConcept.name">
        <accept-concept/>
    </popup>
    <!-- Назначить согласование -->
    <popup :show="popups.scheduleAgreement.show"
           :width="540"
           :name="popups.scheduleAgreement.name">
        <schedule-agreement/>
    </popup>

    <!-- Поставить на паузу -->
    <popup :show="popups.pauseConcept.show"
           :width="540"
           :name="popups.pauseConcept.name">
        <pause-concept/>
    </popup>

    <!-- Заменить концепцию -->
    <popup :show="popups.replaceConcept.show"
           :width="540"
           :name="popups.replaceConcept.name">
        <replace-concept/>
    </popup>

    <!-- Запросить подтверждение -->
    <popup :show="popups.requestConceptConfirm.show"
           :width="540"
           :name="popups.requestConceptConfirm.name">
        <request-concept-confirm/>
    </popup>

    <!-- Возобновить концепцию -->
    <popup :show="popups.resumeConcept.show"
           :width="540"
           :name="popups.resumeConcept.name">
        <resume-concept/>
    </popup>

    <!-- Причина приостановки концепции -->
    <popup :show="popups.pauseReason.show"
           :width="540"
           :name="popups.pauseReason.name">
        <pause-reason/>
    </popup>

    <popup :show="popups.confirmForCustomer.show"
           :width="540"
           :name="popups.confirmForCustomer.name">
        <confirm-for-customer/>
    </popup>
    <!-- Отметить проект готовым -->
    <popup :show="popups.markReady.show"
           :width="540"
           :name="popups.markReady.name">
        <mark-ready/>
    </popup>
    <!-- Сдать чертежи на проверку -->
    <popup :show="popups.drawForReview.show"
           :width="540"
           :name="popups.drawForReview.name">
        <draw-for-review/>
    </popup>
    <!-- Отметить 2D проверенным -->
    <popup :show="popups.markDrawVerify.show"
           :width="540"
           :name="popups.markDrawVerify.name">
        <mark-draw-verify/>
    </popup>

    <!-- Принять решение по ремонту -->
    <popup :show="popups.makeDecision.show"
           :width="540"
           :name="popups.makeDecision.name">
        <make-decision/>
    </popup>

    <!-- Назначить передачу проекта -->
    <popup :show="popups.assignTransfer.show"
           :width="540"
           :name="popups.assignTransfer.name">
        <assign-transfer/>
    </popup>


    <!-- Возобновить 3D-модель -->
    <popup :show="popups.resumeModel.show"
           :width="540"
           :name="popups.resumeModel.name">
        <resume-model/>
    </popup>

    <!-- Причина приостановки -->
    <popup :show="popups.modelPauseReason.show"
           :width="540"
           :name="popups.modelPauseReason.name">
        <model-pause-reason/>
    </popup>

    <!-- Сдать 3d-модель на порверку -->
    <popup :show="popups.modelRequestReview.show"
           :width="540"
           :name="popups.modelRequestReview.name">
        <model-request-review/>
    </popup>

    <!-- Отметить 3d-модель на порверку -->
    <popup :show="popups.modelChecked.show"
           :width="540"
           :name="popups.modelChecked.name">
        <model-checked/>
    </popup>

    <!-- Отметить 3d-модель запросить подтверждение -->
    <popup :show="popups.modelRequestConfirm.show"
           :width="540"
           :name="popups.modelRequestConfirm.name">
        <model-request-confirm/>
    </popup>


    <!-- Поставить на паузу -->
    <popup :show="popups.modelSetPause.show"
           :width="540"
           :name="popups.modelSetPause.name">
        <pause-model/>
    </popup>

    <!-- 3d-модель Подтвердить за заказчика -->
    <popup :show="popups.modelConfirmAsCustomer.show"
           :width="540"
           :name="popups.modelConfirmAsCustomer.name">
        <confirm-model-for-customer/>
    </popup>

    <!-- 3d-модель Назначить демонстрацию -->
    <popup :show="popups.modelDemo.show"
           :width="540"
           :name="popups.modelDemo.name">
        <model-demo/>
    </popup>

    <!-- Какую кнопку нажать? (ремонты) -->
    <popup :show="popups.howPressBtn.show"
           :width="540"
           :name="popups.howPressBtn.name">
        <how-press-btn/>
    </popup>

    <!-- Взять объект в работу -->
    <popup :show="popups.takeToWork.show"
           :width="540"
           :name="popups.takeToWork.name">
        <take-to-work/>
    </popup>

    <!-- Отказать в ремонте -->
    <popup :show="popups.refuseRepair.show"
           :width="540"
           :name="popups.refuseRepair.name">
        <refuse-repair/>
    </popup>
    <!-- Дать заказчику подумать -->
    <popup :show="popups.repairThink.show"
           :width="540"
           :name="popups.repairThink.name">
        <repair-think/>
    </popup>
    <!-- Дать заказчику подумать -->
    <popup :show="popups.repairAside.show"
           :width="540"
           :name="popups.repairAside.name">
        <repair-aside/>
    </popup>
    <!-- Дать заказчику подумать -->
    <popup :show="popups.repairAsideMore.show"
           :width="540"
           :name="popups.repairAsideMore.name">
        <repair-aside-more/>
    </popup>
    <!-- Дать заказчику подумать -->
    <popup :show="popups.repairTerminate.show"
           :width="540"
           :name="popups.repairTerminate.name">
        <repair-terminate/>
    </popup>
    <!-- Дать заказчику подумать -->
    <popup :show="popups.repairFinish.show"
           :width="540"
           :name="popups.repairFinish.name">
        <repair-finish/>
    </popup>
    <!-- Дать заказчику подумать -->
    <popup :show="popups.repairFinishError.show"
           :width="540"
           :name="popups.repairFinishError.name">
        <repair-finish-error/>
    </popup>
    <!-- Дать заказчику подумать -->
    <popup :show="popups.acceptReport.show"
           :width="540"
           :name="popups.acceptReport.name">
        <accept-report/>
    </popup>
    <!-- Откатить статус -->
    <popup :show="popups.restoreStatus.show"
           :width="540"
           :name="popups.restoreStatus.name">
        <restore-status/>
    </popup>
    <!-- Ремонты Взять предоплату -->
    <popup :show="popups.takePrepay.show"
           :width="540"
           :name="popups.takePrepay.name">
        <repair-prepay/>
    </popup>

    <!-- Документы и файлы -->
    <popup :show="popups.docsFiles.show"
           :width="540"
           :name="popups.docsFiles.name">
        <docs-files/>
    </popup>
    <!-- Документы и файлы -->
    <popup :show="popups.editComment.show"
           :width="540"
           :name="popups.editComment.name">
        <edit-comment/>
    </popup>

    <!-- Недозвон -->
    <popup :show="popups.notCall.show"
           :width="540"
           :name="popups.notCall.name">
        <not-call/>
    </popup>

    <!-- Сменить команду -->
    <popup :show="popups.changeZ.show"
           :width="540"
           :name="popups.changeZ.name">
        <change-z/>
    </popup>
    <!-- Сменить команду -->
    <popup :show="popups.changeCommand.show"
           :width="540"
           :name="popups.changeCommand.name">
        <change-command/>
    </popup>
    <popup :show="popups.changeLeader.show"
           :width="540"
           :name="popups.changeLeader.name">
        <change-r/>
    </popup>
    <popup :show="popups.changeCoordinator.show"
           :width="540"
           :name="popups.changeCoordinator.name">
        <change-k/>
    </popup>
    <popup :show="popups.changeAssistant.show"
           :width="540"
           :name="popups.changeAssistant.name">
        <change-a-r/>
    </popup>
    <popup :show="popups.repairNote.show"
           :width="540"
           :name="popups.repairNote.name">
        <repair-note/>
    </popup>
    <popup :show="popups.purposeThink.show"
           :width="540"
           :name="popups.purposeThink.name">
        <purpose-think/>
    </popup>
    <popup :show="popups.purposeRepairNotNeed.show"
           :width="540"
           :name="popups.purposeRepairNotNeed.name">
        <purpose-not-need/>
    </popup>
    <popup :show="popups.purposeSetMaster.show"
           :width="540"
           :name="popups.purposeSetMaster.name">
        <purpose-master-set/>
    </popup>

    <!-- Передать заказчику дизайн -->
    <popup :show="popups.giveDesign.show"
           :width="540"
           :name="popups.giveDesign.name">
        <give-design/>
    </popup>

    <!-- Переместить в посмотрели -->
    <popup :show="popups.moveSeen.show"
           :width="540"
           :name="popups.moveSeen.name">
        <move-seen/>
    </popup>

    <!-- Передача проекта -->
    <popup :show="popups.giveProject.show"
           :width="540"
           :name="popups.giveProject.name">
        <give-project/>
    </popup>
    <!--Назначить созвон-->
    <popup :show="popups.scheduleCall.show"
           :width="540"
           :name="popups.scheduleCall.name">
        <schedule-call/>
    </popup>
    <!--Созвон состоялся-->
    <popup :show="popups.called.show"
           :width="540"
           :name="popups.called.name">
        <called/>
    </popup>
    <!--Не созвонились-->
    <popup :show="popups.didntCall.show"
           :width="540"
           :name="popups.didntCall.name">
        <didntCall/>
    </popup>

</template>

<script>
import { mapState } from 'vuex';
import Popup from '@/components/modal/Popup.vue';
/** MODAL */
import EditMasterComment from '@/components/modal/repair/EditMasterComment.vue';
import EditMasterCommentPrepay from '@/components/modal/repair/EditMasterCommentPrepay.vue';
import MeasureDate from '@/components/modal/requests/MeasureDate.vue';
import AvailableDate from '@/components/modal/requests/AvailableDate.vue';
import CustomerData from '@/components/modal/requests/CustomerData.vue';
import CustomerCharacter from '@/components/modal/requests/CustomerCharacter.vue';
import ObjectAddr from '@/components/modal/requests/ObjectAddr.vue';
import StandardMeasure from '@/components/modal/requests/StandardMeasure.vue';
import RemoteMeasure from '@/components/modal/requests/RemoteMeasure.vue';
import RejectRequest from '@/components/modal/requests/RejectRequest.vue';
import RejectReport from '@/components/modal/requests/RejectReport.vue';
import DocsFiles from '@/components/modal/requests/DocsFiles.vue';
import RefuseReason from '@/components/modal/requests/RefuseReason.vue';
import MeasureDetail from '@/components/modal/requests/MeasureDetail.vue';
import PreApprove from '@/components/modal/requests/PreApprove.vue';
import RefuseCustomer from '@/components/modal/requests/RefuseCustomer.vue';
import ScheduleInterview from '@/components/modal/requests/ScheduleInterview.vue';
import Approve from '@/components/modal/requests/Approve.vue';
import CancelMeasure from '@/components/modal/measures/CancelMeasure.vue';
import DeferMeasure from '@/components/modal/measures/DeferMeasure.vue';
import ConfirmMeasure from '@/components/modal/measures/ConfirmMeasure.vue';
import IssueInvoice from '@/components/modal/measures/IssueInvoice.vue';
import AcceptCash from '@/components/modal/measures/AcceptCash.vue';
import AcceptMeasurerMoney from '@/components/modal/measures/AcceptMeasurerMoney.vue';
import ResumeMeasure from '@/components/modal/measures/ResumeMeasure.vue';
import DeferReason from '@/components/modal/measures/DeferReason.vue';
import RejectReason from '@/components/modal/requests/RejectReason.vue';
import CancelReason from '@/components/modal/measures/CancelReason.vue';
import DownloadConcept from '@/components/modal/concepts/DownloadConcept.vue';
import ResumeConcept from '@/components/modal/concepts/ResumeConcept.vue';
import PauseReason from '@/components/modal/concepts/PauseReason.vue';
import AcceptConcept from '@/components/modal/concepts/AcceptConcept.vue';
import ScheduleAgreement from '@/components/modal/concepts/ScheduleAgreement.vue';
import ReplaceConcept from '@/components/modal/concepts/ReplaceConcept.vue';
import MoveSeen from '@/components/modal/concepts/MoveSeen.vue';
import PauseConcept from '@/components/modal/concepts/PauseConcept.vue';
import RequestConceptConfirm from '@/components/modal/concepts/RequestConceptConfirm.vue';
import ConfirmForCustomer from '@/components/modal/concepts/ConfirmForCustomer.vue';
import MarkReady from '@/components/modal/drawings/MarkReady.vue';
import DrawForReview from '@/components/modal/drawings/DrawForReview.vue';
import MarkDrawVerify from '@/components/modal/drawings/MarkDrawVerify.vue';
import ConfirmModelForCustomer from '@/components/modal/models/ConfirmModelForCustomer.vue';
import ModelPauseReason from '@/components/modal/models/ModelPauseReason.vue';
import ResumeModel from '@/components/modal/models/ResumeModel.vue';
import ModelRequestReview from '@/components/modal/models/ModelRequestReview.vue';
import ModelChecked from '@/components/modal/models/ModelChecked.vue';
import MeasureTime from '@/components/modal/measures/MeasureTime.vue';
import ModelRequestConfirm from '@/components/modal/models/ModelRequestConfirm.vue';
import PauseModel from '@/components/modal/models/PauseModel.vue';
import ModelDemo from '@/components/modal/models/ModelDemo.vue';
import LetCustomerThink from '@/components/modal/LetCustomerThink.vue';
import RequestThink from '@/components/modal/requests/RequestThink.vue';
import RefuseRepair from '@/components/modal/repair/RefuseRepair.vue';
import RepairThink from '@/components/modal/repair/RepairThink.vue';
import RepairAside from '@/components/modal/repair/RepairAside.vue';
import RepairAsideMore from '@/components/modal/repair/RepairAsideMore.vue';
import HowPressBtn from '@/components/modal/repair/HowPressBtn.vue';
import TakeToWork from '@/components/modal/requests/TakeToWork.vue';
import RepairTerminate from '@/components/modal/repair/RepairTerminate.vue';
import RepairFinish from '@/components/modal/repair/RepairFinish.vue';
import RepairFinishError from '@/components/modal/repair/RepairFinishError.vue';
import AcceptReport from '@/components/modal/repair/AcceptReport.vue';
import SendSms from '@/components/modal/requests/SendSms.vue';
import AcceptCard from '@/components/modal/measures/AcceptCard.vue';
import RestoreStatus from '@/components/modal/RestoreStatus.vue';
import RepairPrepay from '@/components/modal/repair/RepairPrepay.vue';
import EditComment from '@/components/modal/requests/EditComment.vue';
import ChangeCommand from '@/components/modal/requests/ChangeCommand.vue';
import ChangeZ from '@/components/modal/requests/ChangeZ.vue';
import ChangeR from '@/components/modal/requests/ChangeR.vue';
import ChangeK from '@/components/modal/requests/ChangeK.vue';
import ChangeAR from '@/components/modal/requests/ChangeAR.vue';
import RepairNote from '@/components/modal/repair/RepairNote.vue';
import PurposeThink from '@/components/modal/purpose/PurposeThink.vue';
import PurposeNotNeed from '@/components/modal/purpose/PurposeNotNeed.vue';
import PurposeMasterSet from '@/components/modal/purpose/PurposeMasterSet.vue';
import NotCall from '@/components/modal/requests/NotCall.vue';
import GiveDesign from '@/components/modal/giveDesign/GiveDesign.vue';
import GiveProject from '@/components/modal/GiveProject.vue';
import ScheduleCall from '@/components/modal/giveDesign/ScheduleCall.vue';
import Called from '@/components/modal/giveDesign/Called.vue';
import DidntCall from '@/components/modal/giveDesign/DidntCall.vue';

export default {
    name: 'RequestPopups',
    components: {
        ChangeK,
        ChangeR,
        RestoreStatus,
        SendSms,
        AcceptCard,
        Popup,
        MeasureDate,
        AvailableDate,
        CustomerData,
        CustomerCharacter,
        ObjectAddr,
        StandardMeasure,
        RemoteMeasure,
        RejectRequest,
        RejectReport,
        RefuseReason,
        MeasureDetail,
        ScheduleInterview,
        Approve,
        PreApprove,
        RefuseCustomer,
        CancelMeasure,
        DeferMeasure,
        ConfirmMeasure,
        IssueInvoice,
        AcceptCash,
        AcceptMeasurerMoney,
        ResumeMeasure,
        DeferReason,
        RejectReason,
        CancelReason,
        DownloadConcept,
        ResumeConcept,
        PauseReason,
        AcceptConcept,
        ScheduleAgreement,
        ReplaceConcept,
        MoveSeen,
        PauseConcept,
        RequestConceptConfirm,
        ConfirmForCustomer,
        MarkReady,
        DrawForReview,
        MarkDrawVerify,
        RefuseRepair,
        RepairThink,
        ConfirmModelForCustomer,
        ModelPauseReason,
        ResumeModel,
        ModelRequestReview,
        ModelChecked,
        ModelRequestConfirm,
        PauseModel,
        MeasureTime,
        ModelDemo,
        LetCustomerThink,
        RequestThink,
        TakeToWork,
        RepairAside,
        RepairAsideMore,
        HowPressBtn,
        RepairTerminate,
        RepairFinish,
        RepairFinishError,
        AcceptReport,
        RepairPrepay,
        DocsFiles,
        EditComment,
        EditMasterComment,
        EditMasterCommentPrepay,
        ChangeCommand,
        ChangeZ,
        ChangeAR,
        RepairNote,
        PurposeThink,
        PurposeNotNeed,
        PurposeMasterSet,
        NotCall,
        GiveDesign,
        GiveProject,
        ScheduleCall,
        Called,
        DidntCall,
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
        }),
    },
};
</script>

<style scoped>

</style>
