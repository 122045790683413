<!--Не созвонились-->
<template>
    <div>
        <div class="popup__header">
            <div class="title">Не созвонились</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <textarea-field :class="'full'"
                            v-model="form.substatus_comment.value"
                            :error-text="''"
                            :label="'Служебный комментарий'"
                            :is-error="form.substatus_comment.error"
                            @focus="form.substatus_comment.error = false"
                            placeholder="Напишите краткую заметку, чтобы после вспомнить ситуацию..."
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Не созвонились»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import InputStyled from '@/components/forms/InputStyled.vue';
import TextareaField from '@/components/forms/TextareaField.vue';

export default {
    name: 'DidntCall',
    components: {
        TextareaField,
        SubmitBtn,
        InputStyled,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        send() {
            let error = false;
            for (let field in this.form) {
                this.form[field].error = false;
                let errorField = false;
                if (this.form[field].value.length == 0) {
                    errorField = true;
                }

                if (errorField) {
                    error = true;
                    setTimeout(() => {
                        this.form[field].error = true;
                    });
                }
            }
            if (!error) {
                let params = {
                    action: 'DidntCall',
                    id: this.item.id,
                    params: {},
                };
                for (let field in this.form) {
                    params.params[field] = this.form[field].value;
                }
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then((result) => {
                    this.btnType = 'ok';
                    if (result.status === 'error') {
                        this.$store.commit('popups/close', { name: this.popups.didntCall.name });
                        this.$store.commit('popups/setErrorCustomText', !!(result.data?.message) ? result.data.message : 'Произошла неизвестная ошибка');
                        this.$store.dispatch('popups/open', { name: this.popups.errorCustomTextModal.name });
                    } else {
                        this.$store.commit('requests/deleteRequest', { id: this.item.id });
                        setTimeout(() => {
                            this.$store.commit('popups/close', { name: this.popups.didntCall.name });
                        }, this.options.delayPopupClose);
                    }
                }, () => {});
            }
        },
    },
    data: function () {
        return {
            btnType: 'normal',
            form: {
                substatus_comment: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
            },

        };
    },
    mounted() {

    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
    },
};
</script>

<style scoped>

</style>
