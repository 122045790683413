<template>
    <div>
        <div class="popup__header">
            <div class="title">Передача проекта</div>
            <div class="text-color-white-trans text-bold mt4">&nbsp;</div>
        </div>


        <div class="popup__content">
            <div>
                <input @change="removeError(form.rate)" v-model="form.rate.value"
                       :class="{error:form.rate.error, 'repeat-error': form.rate.repeatError}" type="radio" id="radio1"
                       value="35">
                <label for="radio1">Всем через собеседование с Алексеем</label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.rate)" v-model="form.rate.value"
                       :class="{error:form.rate.error, 'repeat-error': form.rate.repeatError}" type="radio" id="radio2"
                       value="36">
                <label for="radio2">Москва и Питер — через Алексея, остальным автоматически</label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.rate)" v-model="form.rate.value"
                       :class="{error:form.rate.error, 'repeat-error': form.rate.repeatError}" type="radio" id="radio3"
                       value="37">
                <label for="radio3">Москва — через Алексея, остальным автоматически </label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.rate)" v-model="form.rate.value"
                       :class="{error:form.rate.error, 'repeat-error': form.rate.repeatError}" type="radio" id="radio4"
                       value="38">
                <label for="radio4">Всем автоматически</label>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Tumbler from '@/components/Tumbler.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';

export default {
    name: 'GiveProject',
    components: {
        Tumbler,
        SubmitBtn,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        send() {
            let error = false;
            if (!this.form.rate.value) {
                error = true;
                this.form.rate.error = true;
                console.log('error');
            }
            if (!error) {
                this.btnType = 'load';
                let params = {
                    action: 'saveOption',
                    params: {
                        name: 'rate',
                        value: this.form.rate.value,
                    },
                };
                this.$store.dispatch('options/saveOption', params).then(() => {
                    this.btnType = 'ok';
                    setTimeout(() => {
                        this.$store.commit('popups/close', { name: this.popups.giveProject.name });
                    }, this.options.delayPopupClose);
                });
            }
        },
    },
    data: function () {
        return {
            btnType: 'normal',
            form: {
                rate: {
                    value: false,
                    error: false,
                    repeatError: false,
                },
            },
        };
    },
    mounted() {
        this.form.rate.value = this.options.items.giveProject ? this.options.items.giveProject : 36;
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            options: state => state.options,
        }),
    },
};
</script>

<style scoped>

</style>
